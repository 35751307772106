import revive_payload_client_FdQFTfeelf from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_R3RQlf1h0g from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Lu45SXnJTY from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_daRHaZ0SVJ from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_terser@5_ffu2d3sxlsboojagyxjlonpvky/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ewgie6Os3r from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_514M5SwSPH from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kjKeatQFzI from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Byy1tRaDTr from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GsWD12fWht from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/.nuxt/components.plugin.mjs";
import prefetch_client_bOl288x87z from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import fontawesome_lJWyInPidv from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@vesp+nuxt-fontawesome@1.1.0_magicast@0.3.5_rollup@4.28.0/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import nuxt_plugin_LlbcDTOUMr from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.28.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_brfD5T0HfV from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_xfezR7EolM from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_w016SqEANS from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.0/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_f6k1L19edJ from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.0_typesc_j3mtu23a6dujzuzvvdihkqxhqe/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import base64_2hii42QBJd from "/codebuild/output/src660419847/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  revive_payload_client_FdQFTfeelf,
  unhead_R3RQlf1h0g,
  router_Lu45SXnJTY,
  _0_siteConfig_daRHaZ0SVJ,
  payload_client_ewgie6Os3r,
  navigation_repaint_client_514M5SwSPH,
  check_outdated_build_client_kjKeatQFzI,
  chunk_reload_client_Byy1tRaDTr,
  plugin_vue3_GsWD12fWht,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bOl288x87z,
  plugin_OHU0vggoM9,
  fontawesome_lJWyInPidv,
  nuxt_plugin_LlbcDTOUMr,
  switch_locale_path_ssr_brfD5T0HfV,
  i18n_xfezR7EolM,
  plugin_w016SqEANS,
  plugin_f6k1L19edJ,
  base64_2hii42QBJd
]